<div class="footer flex flex-col gap-4">
    <div class="row flex flex-col justify-center items-center lg:flex-row lg:justify-between gap-2.5">
        <a href="https://ciphersports.io" target="_blank" class="h-full block">
            <img src="https://cdn.ciphersports.io/assets/cipher-logo-with-byline-white.svg" alt="Cipher Sports Technology Group" class="h-10 w-auto" height="22" width="75" loading="lazy">
        </a>
        <a href="https://www.dimers.com" target="_blank" class="h-full block">
            <img src="/assets/Dimers-Slate.svg" alt="Dimers" class="h-10 w-auto" height="67" width="300" loading="lazy">
        </a>
        <span class="copyright text-slate-100 text-center flex-initial">
            Copyright © 2023 Dimers. All Rights Reserved. <a href="https://www.dimers.com/privacy-policy" target="_blank" class="underline">Privacy Policy</a>
        </span>
        <a class="rounded bg-violet-700 hover:bg-violet-600 text-slate-100 px-4 py-2 flex flex-row justify-center items-center gap-4 flex-none" href="https://www.twitter.com/DimersCom" target="_blank">
            <fa-icon [icon]="faTwitter" alt="Twitter" class="text-xl"></fa-icon>
            Join the community
        </a>
    </div>
    <div class="disclaimer max-w-full text-slate-300 text-xs">
        Disclaimer: All of the information on this site is for entertainment purposes only. We do NOT accept bets of any kind. The information we provide is accurate and trustworthy to help you make better decisions. When you click or tap on a link on Dimers that leads to a third-party website that we have a commercial arrangement with (such as an online sportsbook), we may earn referral fees. Dimers does not endorse or encourage illegal or irresponsible gambling in any form. Before placing any wagers with any betting site, you must check the online gambling regulations in your jurisdiction or state, as they do vary. If you or someone you know has a gambling problem, crisis counseling and referral services can be accessed by calling 1-800-GAMBLER.
    </div>
</div>