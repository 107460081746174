import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeedbackFormComponent } from './components/feedback-form/feedback-form.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';


@NgModule({
    declarations: [FeedbackFormComponent],
    imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, FontAwesomeModule, RecaptchaModule, RecaptchaFormsModule],
    exports: [FeedbackFormComponent]
})
export class SharedModule { }
