import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BookPipesModule } from './book-pipes/book-pipes.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GoogleTagManagerModule } from './angular-google-tag-manager.module';
import { FooterComponent } from './footer/footer.component';
import { CookieModule } from 'ngx-cookie';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    BookPipesModule,
    FontAwesomeModule,
    GoogleTagManagerModule.forRoot({
			id: "GTM-T3ZSX2J",
		}),
    FooterComponent,
    SharedModule,
    CookieModule.withOptions(),
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
