import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path: ":sport", loadComponent: () => import('./picks-page/picks-page.component').then(m => m.PicksPageComponent)},
  {path: "", pathMatch: "full", loadComponent: () => import('./waitlist-page/waitlist-page.component').then(m => m.WaitlistPageComponent)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
