import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { GoogleTagManagerService } from './angular-google-tag-manager.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Dimers Bettorverse';
  browserMode: boolean;

  constructor(
    private gtmService: GoogleTagManagerService,
    @Inject(PLATFORM_ID) platformId: string,
  ) {
    this.browserMode = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    if (this.browserMode) {
      const gtmTag = {
        event: "page",
        pageName: window?.location.pathname.toLowerCase(),
      };
  
      this.gtmService.pushTag(gtmTag)
        .catch(e => {
          throw new Error("Google Tag Manager Script failed: " + e)
        });
    }
    
  }
}
